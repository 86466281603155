(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/join-team/assets/javascripts/services/fetch-invitation.js') >= 0) return;  svs.modules.push('/components/marketplace-data/join-team/assets/javascripts/services/fetch-invitation.js');
"use strict";

const {
  jupiter
} = svs.core;
const fetchInvitation = (invitationToken, callback) => {
  jupiter.get("/marketplace/1/groups/invitation/".concat(invitationToken), response => {
    callback(undefined, {
      groupId: response.invitationInfo.groupId,
      groupName: response.invitationInfo.groupName,
      isMember: response.invitationInfo.isMember,
      maxEntries: response.invitationInfo.maxEntries,
      openSpots: response.invitationInfo.openSpots,
      status: response.invitationInfo.status,
      usages: response.invitationInfo.usages,
      validUntil: response.invitationInfo.validUntil
    });
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.joinTeam.services.fetchInvitation', fetchInvitation);

 })(window);