(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/join-team/assets/javascripts/join-team-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/join-team/assets/javascripts/join-team-actions.js');
"use strict";


const {
  dialogWrapper
} = svs.components.marketplaceData;
const {
  services
} = svs.components.marketplaceData.joinTeam;
const {
  isPast
} = dateFns;
const {
  errorCodeHandler
} = svs.components.marketplaceData;
const ACCEPT_INVITE_INIT = 'joinTeam/ACCEPT_INVITE_INIT';
const ACCEPT_INVITE_SUCCESS = 'joinTeam/ACCEPT_INVITE_SUCCESS';
const ACCEPT_INVITE_FAILURE = 'joinTeam/ACCEPT_INVITE_FAILURE';
const FETCH_INVITATION_INIT = 'joinTeam/FETCH_INVITATION_INIT';
const FETCH_INVITATION_SUCCESS = 'joinTeam/FETCH_INVITATION_SUCCESS';
const FETCH_INVITATION_FAILURE = 'joinTeam/FETCH_INVITATION_FAILURE';
const FETCH_INVITATION_ALREADY_MEMBER = 'joinTeam/FETCH_INVITATION_ALREADY_MEMBER';
const CANCEL_JOIN = 'joinTeam/CANCEL_JOIN';
const acceptInvite = function (invitationToken) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
  return (dispatch, getState) => {
    const teamName = getState().JoinTeam.teamName || 'laget';
    dispatch({
      type: ACCEPT_INVITE_INIT,
      invitationToken
    });
    services.acceptInvite(invitationToken, (error, response) => {
      if (error) {
        errorCodeHandler.getErrorMessage(error.code, err => {
          dispatch({
            type: ACCEPT_INVITE_FAILURE,
            err,
            invitationToken
          });
          dispatch(dialogWrapper.actions.showDialog({
            title: err.message,
            type: 'error'
          }));
          callback(null);
        }, {
          shouldCheckPlayerExclusions: true,
          joinTeamMessage: true,
          name: teamName
        });
      } else {
        dispatch({
          type: ACCEPT_INVITE_SUCCESS,
          groupId: response.groupId,
          invitationToken
        });
        dispatch(dialogWrapper.actions.showDialog({
          title: "Du \xE4r nu medlem i ".concat(teamName, "!"),
          icon: 'checkmark'
        }));
        callback(null);
      }
    });
  };
};
const fetchInvitation = function (invitationToken) {
  let callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
  return dispatch => {
    dispatch({
      type: FETCH_INVITATION_INIT,
      invitationToken
    });
    services.fetchInvitation(invitationToken, (error, result) => {
      if (error) {
        dispatch(dialogWrapper.actions.showDialog({
          title: error.code !== 404 ? 'Något gick fel, försök igen senare' : "Inbjudningsl\xE4nken \xE4r ogiltig. Kontrollera att den \xE4r r\xE4tt och att laget finns kvar.",
          type: 'help-2'
        }));
        dispatch({
          type: FETCH_INVITATION_FAILURE,
          error,
          invitationToken
        });
        callback(error);
      } else if (result.usages >= result.maxEntries) {
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Laget är fullt.',
          icon: 'help-2'
        }));
        dispatch({
          type: FETCH_INVITATION_FAILURE,
          error: {
            message: 'No empty spots in team'
          },
          invitationToken
        });
        callback();
      } else if (result.isMember) {
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Du är redan medlem i laget',
          icon: 'help-2'
        }));
        dispatch({
          type: FETCH_INVITATION_ALREADY_MEMBER,
          teamName: result.groupName,
          groupId: result.groupId
        });
        callback();
      } else if (isPast(new Date(result.validUntil))) {
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Inbjudan är inte längre giltig.',
          icon: 'help-2'
        }));
        dispatch({
          type: FETCH_INVITATION_FAILURE,
          error: {
            message: 'Invitation has passed valid date'
          },
          invitationToken
        });
        callback();
      } else {
        dispatch({
          type: FETCH_INVITATION_SUCCESS,
          result
        });
        callback();
      }
    });
  };
};
const cancelJoin = () => ({
  type: CANCEL_JOIN
});
setGlobal('svs.components.marketplaceData.joinTeam.actions', {
  ACCEPT_INVITE_INIT,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  FETCH_INVITATION_INIT,
  FETCH_INVITATION_SUCCESS,
  FETCH_INVITATION_FAILURE,
  FETCH_INVITATION_ALREADY_MEMBER,
  CANCEL_JOIN,
  acceptInvite,
  fetchInvitation,
  cancelJoin
});

 })(window);