(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/join-team/assets/javascripts/services/accept-invite.js') >= 0) return;  svs.modules.push('/components/marketplace-data/join-team/assets/javascripts/services/accept-invite.js');
"use strict";

const {
  jupiter
} = svs.core;
const acceptInvite = (invitationToken, callback) => {
  jupiter.put("/marketplace/1/invitationlinks/".concat(invitationToken), response => {
    callback(undefined, response.AddMemberReply);
  }, error => {
    callback(error.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.joinTeam.services.acceptInvite', acceptInvite);

 })(window);